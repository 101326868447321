import React, { useEffect, useState } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import gerarToken from '../../../helpers/gerarToken';
import { useDispatch, useSelector } from 'react-redux';
import { requisitarDados } from "../../../store/btflex/indicadoresVendaEstagios/actions";
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { Card, CardBody, FormGroup, Button, Form, Row, Col, Collapse, Input } from 'reactstrap';
import { Hoje, Mes, Ano, removerFocusNoBotao, retornarMeses, retornarAnosFinal } from "../../../helpers/utils";
import { dadosVendedoresBtflex, dadosUsuarioBtflexLogado } from '../../../helpers/api';
import IndicadoresIcones from "../../../components/IndicadoresIcones";

const meses = retornarMeses();
const anos = retornarAnosFinal(parseInt(Ano()), 2022);

const IndicadoresVendaEstagios = (props) => {
  const { dados, carregando } = useSelector(state => state.IndicadoresVendaEstagios);
  const [mes, setMes] = useState(Mes(Hoje()));
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [ano, setAno] = useState(Ano(Hoje()));
  const [vendedorSelecionado, setVendedorSelecionado] = useState("");
  const [vendedoresBtflex, setVendedoresBtflex] = useState([]);
  const [isVendedor, setIsVendedor] = useState(false);
  const dispatch = useDispatch();

  const atualizarDados = async () => {
    if (!mes && !ano) return;
    const token = await gerarToken();
    await dispatch(requisitarDados({ token, ano, mes, id_vendedor: vendedorSelecionado }));
  };

  useEffect(() => {
    async function consultarListaVendedores() {
      const token = await gerarToken();
      const auxDdlDadosVendedor = await dadosVendedoresBtflex({ token });
      setVendedoresBtflex(auxDdlDadosVendedor.ResultadoObjeto);
    }

    async function verificarUsuario() {
      const token = await gerarToken();
      const auxDdlDadosUsuarioBtflexLogado = await dadosUsuarioBtflexLogado({ token });
      setIsVendedor(auxDdlDadosUsuarioBtflexLogado.ResultadoObjeto[0].usuario_vendedor === 1);
    }

    consultarListaVendedores();
    verificarUsuario();
    atualizarDados();
  }, []);

  const trocarMes = (e) => {
    setMes(e.target.value);
  };

  const trocarAno = (e) => {
    setAno(e.target.value);
  };

  const trocarVendedorSelecionado = (e) => {
    setVendedorSelecionado(e.target.value);
  };

  const aplicarFiltro = (e) => {
    atualizarDados();
    removerFocusNoBotao(e.currentTarget);
  };

  return (
    <React.Fragment>
      <PaginaLoader carregando={carregando} />
      <>
        <Header
          parametros={{
            botoesExtras: [
              {
                funcao: () => {
                  setMostrarFiltros(!mostrarFiltros);
                },
                icone: "bx bx bx-filter-alt",
              },
            ],
          }}
        />
        <div id="layout-wrapper">
          <Collapse isOpen={mostrarFiltros}>
            <Card className="mb-0">
              <CardBody>
                <div className="d-flex flex-wrap toolBarVard">
                  <div className="d-flex d-md-block toolbar toolbar-card">
                    <Form inline>
                      {!isVendedor && (
                        <FormGroup>
                          <Input
                            defaultValue={vendedorSelecionado}
                            type="select"
                            onChange={trocarVendedorSelecionado}
                            className="ml-2"
                          >
                            <option value="">Todos os vendedores</option>
                            {vendedoresBtflex.map((vendedor) => (
                              <option key={vendedor.value} value={vendedor.value}>
                                {vendedor.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      )}
                      <FormGroup>
                        <Input defaultValue={mes} type="select" onChange={trocarMes}>
                          {meses.map((mes) => (
                            <option key={mes.value} value={mes.value}>
                              {mes.label}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          defaultValue={ano}
                          type="select"
                          onChange={trocarAno}
                          className="ml-2"
                        >
                          {anos.map((ano) => (
                            <option key={ano.value} value={ano.value}>
                              {ano.label}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Button
                          color="light"
                          onClick={(e) => aplicarFiltro(e)}
                          className="ml-2"
                        >
                          <i className="fal fa-rabbit-fast mr-1"></i>
                          vai...
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Collapse>

          <div className="p-2 pt-3 pdF" >
            <Row>
              <Col sm={6} md={4} lg={4} xl={2} className="mt-2">
                <IndicadoresIcones
                  estagio="Floresta"
                  icone="fad fa-trees"
                  pedidos={dados.length ? dados[0].florestaPedidos : ""}
                  valor={dados.length ? dados[0].florestaValor : ""}
                  status={dados.length ? dados[0].florestaStatus : ""}
                  ano={ano}
                  mes={mes}
                  vendedor={vendedorSelecionado}
                />
              </Col>
              <Col sm={6} md={4} lg={4} xl={2} className="mt-2">
                <IndicadoresIcones
                  estagio="Tá quente"
                  icone="fad fa-fire-alt"
                  pedidos={dados.length ? dados[0].taQuentePedidos : ""}
                  valor={dados.length ? dados[0].taQuenteValor : ""}
                  status={dados.length ? dados[0].taQuenteStatus : ""}
                  ano={ano}
                  mes={mes}
                  vendedor={vendedorSelecionado}
                />
              </Col>
              <Col sm={6} md={4} lg={4} xl={2} className="mt-2">
                <IndicadoresIcones
                  estagio="Em análise"
                  icone="fad fa-list"
                  pedidos={dados.length ? dados[0].analisePedidos : ""}
                  valor={dados.length ? dados[0].analiseValor : ""}
                  status={dados.length ? dados[0].analiseStatus : ""}
                  ano={ano}
                  mes={mes}
                  vendedor={vendedorSelecionado}
                />
              </Col>
              <Col sm={6} md={4} lg={4} xl={2} className="mt-2">
                <IndicadoresIcones
                  estagio="Fechado"
                  icone="fad fa-thumbs-up"
                  pedidos={dados.length ? dados[0].fechadoPedidos : ""}
                  valor={dados.length ? dados[0].fechadoValor : ""}
                  status={dados.length ? dados[0].fechadoStatus : ""}
                  ano={ano}
                  mes={mes}
                  vendedor={vendedorSelecionado}
                />
              </Col>

              <Col sm={6} md={4} lg={4} xl={2} className="mt-2">
                <IndicadoresIcones
                  estagio="Entrega futura"
                  icone="fad fa-truck-container"
                  pedidos={dados.length ? dados[0].remessaFuturaPedidos : ""}
                  valor={dados.length ? dados[0].remessaFuturaValor : ""}
                  status={dados.length ? dados[0].remessaFuturaStatus : ""}
                  ano={ano}
                  mes={mes}
                  vendedor={vendedorSelecionado}
                />
              </Col>

              <Col sm={6} md={4} lg={4} xl={2} className="mt-2">
                <IndicadoresIcones
                  estagio="Em separação"
                  icone="fad fa-truck"
                  pedidos={dados.length ? dados[0].separacaoPedidos : ""}
                  valor={dados.length ? dados[0].separacaoValor : ""}
                  status={dados.length ? dados[0].separacaoStatus : ""}
                  ano={ano}
                  mes={mes}
                  vendedor={vendedorSelecionado}
                />
              </Col>

              <Col sm={6} md={4} lg={4} xl={2} className="mt-2">
                <IndicadoresIcones
                  estagio="Faturado"
                  icone="fad fa-check-double"
                  pedidos={dados.length ? dados[0].finalizadoPedidos : ""}
                  valor={dados.length ? dados[0].finalizadoValor : ""}
                  status={dados.length ? dados[0].finalizadoStatus : ""}
                  ano={ano}
                  mes={mes}
                  vendedor={vendedorSelecionado}
                />
              </Col>
            </Row>
          </div>
        </div>
      </>
    </React.Fragment>
  );
}
export default IndicadoresVendaEstagios;