import React, { useEffect, useState, useRef, useMemo } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { requisitarDados, requisitarLimparDados } from "../../../store/benassi/gridMargemProduto/actions";
import { gridConfig } from './AppConfig';
import { FormGroup, Button, Form, Input } from 'reactstrap';
import { retornarTiposPeriodo, retornarTipoCustos, concatenarDropdown, retornarAnos, retornarMeses, Hoje, Mes, Ano, removerFocusNoBotao, primeiroDiaMes, toDateSQL, concatenaLojas, exportarExcel } from "../../../helpers/utils";
import { requisitarDadosAmbiente } from "../../../store/ambiente/actions";
import Flatpickr from "react-flatpickr";
import { Portuguese } from "../../../helpers/locale/portuguese";
import '@bryntum/grid/grid.stockholm.css';
import '../../../App.scss';
import Header from "../../../components/HorizontalLayout/HeaderApp";
import { BryntumGrid } from '@bryntum/grid-react';
import FiltroBrytum from "../../../components/filtroBrytum";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import FocarPaisagem from '../../../components/forcarPaisagem';
const anos = retornarAnos(6);
const meses = retornarMeses();
const custosDropdown = retornarTipoCustos();
const animatedComponents = makeAnimated();
const tiposPeriodo = retornarTiposPeriodo();

const GridMargemProduto = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const filtros = location?.state?.filtros;

    const [loading, setLoading] = useState(true);
    const { dados, carregando } = useSelector(state => state.GridMargemProduto);
    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [habilitarFiltroColunas, setHabilitarFiltroColunas] = useState(true);
    const token = useSelector(state => state.Login.user.tokenNOL);
    const [ano, setAno] = useState(anos[0]);
    const [tipoPeriodo, setTipoPeriodo] = useState(tiposPeriodo[0]);
    const [custos, setCustos] = useState(custosDropdown[1]);
    const [mesesSelecionados, setMesesSelecionados] = useState(meses.filter(a => a.value == Mes(Hoje())));
    const [dataInicial, setDataInicial] = useState(primeiroDiaMes(Mes()));
    const [dataFinal, setDataFinal] = useState(Hoje());
    const mesesSelecionadosRef = useRef(null);
    const anoRef = useRef(null);
    const { lojasDoUsuario } = useSelector(state => state.Ambiente);
    const [lojas, setLojas] = useState([]);


    anoRef.current = { ano, setAno };
    mesesSelecionadosRef.current = { mesesSelecionados, setMesesSelecionados };

    const lojasRef = useRef(null);
    lojasRef.current = { lojas, setLojas };


    const atualizarDados = async () => {
        await dispatch(requisitarDados({
            u_unidades_id: filtros.filial,
            u_negocios_id: filtros.negocio,
            r_redes_id: filtros.rede,
            t_terceiros_id: filtros.loja,
            c_comprador_id: filtros.comprador,
            v_vendedor_id: filtros.vendedor,
            p_grade_01: filtros.grupoProduto,
            p_produto: filtros.produto,
            agrupamento: "total",
            visualizacao: "grupo-produto",
            retorna_desafio: 1,
            arvore: 4,
            data_inicial: filtros.tipoPeriodo == "2" ? toDateSQL(filtros.dataInicial) : "",
            data_final: filtros.tipoPeriodo == "2" ? toDateSQL(filtros.dataFinal) : "",
            ano: filtros.tipoPeriodo == "1" ? filtros.ano : "",
            meses: filtros.tipoPeriodo == "1" ? filtros.mes : "",
            classeN1: "pto",
            sob_demanda: 1,
            nivel_atual: 0,
            retornaMC3: 1,
            tipo_cmv: filtros.tipoCusto == "01" ? "medio" : "gerencial",
            token,
            exportacao: "matrix-de-produtos"
        }));
        setMostrarFiltros(false);
    }
    const atualizarDadosAmbiente = async () => {
        await dispatch(requisitarDadosAmbiente(token));
    }

    useEffect(() => {
        if (!filtros) return navigate("/filtro/matrix-de-produtos");
        atualizarDadosAmbiente();
        atualizarDados();
        return () => dispatch(requisitarLimparDados())
    }, []);

    useEffect(() => {
        if (carregando) return;
        atualizarDadosDemanda();
    }, [carregando]);

    const atualizarDadosDemanda = () => {
        setMostrarFiltros(false);
        setLoading(true);
        if (grid.current) {
            grid.current.instance.store.fetchOptions.body = JSON.stringify({
                u_unidades_id: filtros.filial,
                u_negocios_id: filtros.negocio,
                r_redes_id: filtros.rede,
                t_terceiros_id: filtros.loja,
                c_comprador_id: filtros.comprador,
                v_vendedor_id: filtros.vendedor,
                p_grade_01: filtros.grupoProduto,
                p_produto: filtros.produto,
                agrupamento: "total",
                visualizacao: "grupo-produto",
                retorna_desafio: 1,
                arvore: 4,
                data_inicial: filtros.tipoPeriodo == "2" ? toDateSQL(filtros.dataInicial) : "",
                data_final: filtros.tipoPeriodo == "2" ? toDateSQL(filtros.dataFinal) : "",
                ano: filtros.tipoPeriodo == "1" ? filtros.ano : "",
                meses: filtros.tipoPeriodo == "1" ? filtros.mes : "",
                classeN1: "pto",
                sob_demanda: 1,
                nivel_atual: 0,
                retornaMC3: 1,
                tipo_cmv: filtros.tipoCusto == "01" ? "medio" : "gerencial",
                token,
                exportacao: "matrix-de-produtos"
            });

            grid.current.instance.store.removeAll();
            grid.current.instance.store.loadData();
            grid.current.instance.store.load()
                .then((resolve) => {
                    // grid.current.instance.columns.get("cmv_valor").hidden = true;
                    // grid.current.instance.columns.get("cmv_02_valor").hidden = true;
                    // grid.current.instance.columns.get("margem_contribuicao_valor").hidden = true;
                    // grid.current.instance.columns.get("margem_contribuicao_02_valor").hidden = true;
                    // if (custos.value == "01") {
                    //     grid.current.instance.columns.get("cmv_valor").hidden = false;
                    //     grid.current.instance.columns.get("margem_contribuicao_valor").hidden = false;
                    // } else {
                    //     grid.current.instance.columns.get("cmv_02_valor").hidden = false;
                    //     grid.current.instance.columns.get("margem_contribuicao_02_valor").hidden = false;
                    // }
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const gridInfo = useMemo(() => {
        if (!dados) return
        return gridConfig(dados, grid, token);
    }, [dados])

    const trocarLojas = (valor) => {
        if (valor) {
            setLojas(valor)
        } else {
            setLojas("")
        }
    }

    const trocarAno = (valor) => {
        if (valor) {
            setAno(valor)
        } else {
            setAno([])
        }
    }

    const trocarTiposPeriodo = (valor) => {
        if (valor) {
            setTipoPeriodo(valor);
        } else {
            setTipoPeriodo([]);
        }
    }

    const trocarCustos = (valor) => {
        if (valor) {
            setCustos(valor)
        } else {
            setCustos([])
        }
    }

    const trocarMes = (valor) => {
        if (valor) {
            setMesesSelecionados(valor)
        } else {
            setMesesSelecionados([])
        }
    }

    return (
        <React.Fragment>
            <FocarPaisagem />
            <PaginaLoader carregando={loading} />
            <>
                <Header
                    url={"/filtro/matrix-de-produtos/"}
                    parametros={{
                        exportarExcel: () => exportarExcel({ data: grid.current.gridInstance.store.data, exportacao: dados.exportacao }),
                        botoesExtras: [
                            {
                                funcao: () => {
                                    setHabilitarFiltroColunas(!habilitarFiltroColunas)
                                },
                                icone: "bx bx-filter"
                            }
                        ]
                    }
                    } position={true} titulo="Mapa de negócios" />
                <FiltroBrytum aberto={mostrarFiltros}>
                    <Form inline>
                        <FormGroup className="select2-container"  >
                            <div className="unidadesControler">
                                <Select
                                    placeholder="Todas as unidades"
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={lojasDoUsuario}
                                    isSearchable={false}
                                    isMulti
                                    classNamePrefix="select2-selection"
                                    defaultValue={lojas || []}
                                    onChange={Object => {
                                        trocarLojas(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <div className="unidadesControler anoControler ml-2">
                                <Select
                                    placeholder=""
                                    className="basic-single"
                                    options={tiposPeriodo}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={tiposPeriodo[0] || []}
                                    onChange={Object => {
                                        trocarTiposPeriodo(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        {tipoPeriodo.value == "1" &&
                            <FormGroup>
                                <div className="unidadesControler anoControler ml-2">
                                    <Select
                                        placeholder="Todas as unidades"
                                        className="basic-single"
                                        options={anos}
                                        isSearchable={false}
                                        classNamePrefix="select2-selection"
                                        defaultValue={anos[0] || []}
                                        onChange={Object => {
                                            trocarAno(Object || []);
                                        }}
                                    />
                                </div>
                            </FormGroup>
                        }
                        {tipoPeriodo.value == "1" &&
                            <FormGroup className="select2-container ml-2"  >
                                <div className="unidadesControler">
                                    <Select
                                        defaultValue={meses.filter(a => a.value == Mes(Hoje()))}
                                        isMulti
                                        name="meses"
                                        options={meses}
                                        placeholder="Todos os meses"
                                        closeMenuOnSelect={false}
                                        classNamePrefix="select2-selection"
                                        isSearchable={false}
                                        onChange={Object => {
                                            trocarMes(Object || []);
                                        }}
                                    />
                                </div>
                            </FormGroup>
                        }
                        {tipoPeriodo.value == "2" &&
                            <FormGroup style={{ width: 110 }}>
                                <Flatpickr
                                    className="form-control d-block w-100 ml-2"
                                    placeholder="dd M,yyyy"
                                    locale="pt"
                                    options={{
                                        mode: "single",
                                        locale: Portuguese,
                                        dateFormat: "d/m/Y",
                                        maxDate: `today`,
                                        defaultDate: dataInicial,
                                        disableMobile: "true"

                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                        if (selectedDates.length > 0) {
                                            setDataInicial(selectedDates[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        }
                        {tipoPeriodo.value == "2" && <div className={"ml-1 mr-1"} >a</div>}
                        {tipoPeriodo.value == "2" &&
                            <FormGroup style={{ width: 110 }}>
                                <Flatpickr
                                    className="form-control d-block w-100"
                                    placeholder="dd M,yyyy"
                                    locale="pt"
                                    options={{
                                        mode: "single",
                                        locale: Portuguese,
                                        dateFormat: "d/m/Y",
                                        maxDate: `today`,
                                        defaultDate: "today",
                                        disableMobile: "true"
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                        if (selectedDates.length > 0) {
                                            setDataFinal(selectedDates[0]);
                                        }
                                    }}
                                />
                            </FormGroup>
                        }
                        <FormGroup className="ml-2">
                            <div className="unidadesControler">
                                <Select
                                    placeholder=""
                                    className="basic-single"
                                    options={custosDropdown}
                                    isSearchable={false}
                                    classNamePrefix="select2-selection"
                                    defaultValue={custosDropdown[1] || []}
                                    onChange={Object => {
                                        trocarCustos(Object || []);
                                    }}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button color="light" onClick={(e) => { atualizarDadosDemanda(); removerFocusNoBotao(e.currentTarget); }} className="ml-2">
                                <i className="fal fa-hashtag"></i>
                                boralá!
                            </Button>
                        </FormGroup>
                    </Form>
                </FiltroBrytum>
                {dados
                    &&
                    <BryntumGrid
                        ref={grid}
                        {...gridInfo}
                        treeFeature={true}
                        columnReordFeature={false}
                        cellEditFeature={false}
                        summaryFeature={true}
                        sortFeature={true}
                        key={carregando}
                        filterBarFeature={{
                            compactMode: habilitarFiltroColunas
                        }}
                        cellMenuFeature={
                            {
                                disabled: true,
                            }
                        }
                        headerMenuFeature={
                            {
                                items: {
                                    hideColumn: false
                                }
                            }
                        }
                    />}

            </>
        </React.Fragment>
    );
}
export default GridMargemProduto;