import React, { useEffect, useState, useMemo, useRef } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import gerarToken from '../../../helpers/gerarToken'
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados } from "../../../store/btflex/vendedores/actions"
import { gridConfig } from './AppConfig';
import { Card, CardBody, FormGroup, Button, Form, Collapse, Input } from 'reactstrap';
import { Hoje, Mes, Ano, removerFocusNoBotao, retornarMeses, retornarAnosFinal } from "../../../helpers/utils";
import '@bryntum/grid/grid.stockholm.css';
import '../../../App.scss';
import Header from "../../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
import { dadosVendedoresBtflex, dadosUsuarioBtflexLogado } from '../../../helpers/api';

const meses = retornarMeses();
const anos = retornarAnosFinal(parseInt(Ano()), 2022);

const Vendedores = (props) => {
    const { dados, carregando } = useSelector(state => state.Vendedores);
    const [mes, setMes] = useState(Mes(Hoje()));
    const [ano, setAno] = useState(Ano(Hoje()));
    const grid = useRef(null);
    const dispatch = useDispatch();
    const [mostrarFiltros, setMostrarFiltros] = useState(false);
    const [vendedorSelecionado, setVendedorSelecionado] = useState("");
    const [vendedoresBtflex, setVendedoresBtflex] = useState([]);
    const [isVendedor, setIsVendedor] = useState(false);

    const atualizarDados = async () => {
        if (!mes && !ano) return;
        const token = await gerarToken();
        await dispatch(requisitarDados({ token, ano, mes, id_vendedor: vendedorSelecionado }));
    };

    useEffect(() => {
        async function consultarListaVendedores() {
            const token = await gerarToken();
            const auxDdlDadosVendedor = await dadosVendedoresBtflex({ token });
            setVendedoresBtflex(auxDdlDadosVendedor.ResultadoObjeto);
        }

        async function verificarUsuario() {
            const token = await gerarToken();
            const auxDdlDadosUsuarioBtflexLogado = await dadosUsuarioBtflexLogado({ token });
            setIsVendedor(auxDdlDadosUsuarioBtflexLogado.ResultadoObjeto[0].usuario_vendedor === 1);
        }

        consultarListaVendedores();
        verificarUsuario();
        atualizarDados();
    }, []);


    const gridInfo = useMemo(() => {
        return gridConfig(dados, grid);
    }, [dados]);

    if (!dados) return <PaginaLoader carregando={true} />

    const trocarMes = (e) => {
        setMes(e.target.value);
    }

    const trocarAno = (e) => {
        setAno(e.target.value);
    }

    const trocarVendedorSelecionado = (e) => {
        setVendedorSelecionado(e.target.value);
    };

    const aplicarFiltro = (e) => {
        atualizarDados();
        removerFocusNoBotao(e.currentTarget);
    };

    return (
        <React.Fragment>
            <PaginaLoader carregando={carregando} />
            <Header
                parametros={{
                    botoesExtras: [
                        {
                            funcao: () => {
                                setMostrarFiltros(!mostrarFiltros)
                            },
                            icone: "bx bx bx-filter-alt"
                        }
                    ]
                }}
                position={true} titulo="Mapa de negócios" />
            <Collapse isOpen={mostrarFiltros}>
                <Card className="mb-0">
                    <CardBody className="altoZIndex barBrytum">
                        <div className="d-flex flex-wrap toolBarVard">
                            <div className="d-flex d-md-block toolbar toolbar-card">
                                <Form inline>
                                    {!isVendedor && (
                                        <FormGroup>
                                            <Input
                                                defaultValue={vendedorSelecionado}
                                                type="select"
                                                onChange={trocarVendedorSelecionado}
                                                className="ml-2"
                                            >
                                                <option value="">Todos os vendedores</option>
                                                {vendedoresBtflex.map((vendedor) => (
                                                    <option key={vendedor.value} value={vendedor.value}>
                                                        {vendedor.label}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                    )}
                                    <FormGroup>
                                        <Input defaultValue={mes} type="select" onChange={trocarMes}>
                                            {
                                                meses.map((mes, indice) => {
                                                    return (
                                                        <option key={`mes_${indice}`} value={mes.value}>{mes.label}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input defaultValue={ano} type="select" onChange={trocarAno} className="ml-2">
                                            {
                                                anos.map((ano, indice) => {
                                                    return (
                                                        <option key={`ano_${indice}`} value={ano.value}>{ano.label}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Button
                                            color="light"
                                            onClick={(e) => aplicarFiltro(e)}
                                            className="ml-2"
                                        >
                                            <i className="fal fa-rabbit-fast mr-1"></i>
                                            Vai...
                                        </Button>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Collapse>
            <BryntumGrid
                ref={grid}
                {...gridInfo}
                treeFeature={true}
                columnReordFeature={false}
                cellEditFeature={false}
                summaryFeature={true}
                sortFeature={false}
                key={carregando + vendedorSelecionado}
                cellMenuFeature={{
                    disabled: true,
                }}
                headerMenuFeature={{
                    items: {
                        hideColumn: false
                    }
                }}
            />
        </React.Fragment>
    );
}

export default Vendedores;