import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card, CardBody, Label, FormGroup, Input, Button } from "reactstrap";
import { retornarTipoDesempenho, retornarTipoCustos, Mes, retornarMeses, retornarAnos, retornarTiposPeriodo, concatenaLojas, Hoje, primeiroDiaMes, toDateSQL, ArrayToMultiSelect } from "../../../helpers/utils"

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { requisitarDados } from "../../../store/menus/actions";
import { requisitarDadosFilial } from "../../../store/benassi/filial/actions";
import { requisitarDadosNegocio } from "../../../store/benassi/negocio/actions";
import { requisitarDadosRede } from "../../../store/benassi/rede/actions";
import { requisitarDadosLoja } from "../../../store/benassi/loja/actions";
import { requisitarDadosComprador } from "../../../store/benassi/comprador/actions";
import { requisitarDadosGrupoProduto } from "../../../store/benassi/grupoProduto/actions";
import { requisitarDadosVendedor } from "../../../store/benassi/vendedor/actions";
import PaginaLoader from '../../../components/loader/pagina';
import { useSelector } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import BtnLoader from '../../../components/forms/btnLoader';
import makeAnimated from 'react-select/animated';
import Flatpickr from "react-flatpickr"
import Select from 'react-select'
import { Portuguese } from "../../../helpers/locale/portuguese";
const animatedComponents = makeAnimated();
const anos = retornarAnos(6);
const meses = retornarMeses();
const tiposPeriodo = retornarTiposPeriodo();
const mesAutal = meses.filter(a => a.value == Mes(Hoje()))[0];
const tiposCusto = retornarTipoCustos();
const tiposDesempenho = retornarTipoDesempenho();
const PreFiltroUnidades = () => {
    const token = useSelector(state => state.Login.user.tokenNOL);
    const { carregando, atalhos, menuPrincipal } = useSelector(state => state.Menus)

    const { pagina } = useParams();
    const navigate = useNavigate();

    const filiais = useSelector(state => state.Filial.dados);
    const negocios = useSelector(state => state.Negocio.dados);
    const redes = useSelector(state => state.Rede.dados);
    const lojas = useSelector(state => state.Loja.dados);


    const [filial, setFilial] = useState("");
    const [filialSelecionada, setFilialSelecionada] = useState("");
    const [negocio, setNegocio] = useState("");
    const [negocioSelecionado, setNegocioSelecionado] = useState("");
    const [rede, setRede] = useState("");
    const [redeSelecionada, setRedeSelecionada] = useState("");
    const [loja, setLoja] = useState("");
    const [tipoPeriodo, setTipoPeriodo] = useState(tiposPeriodo[0].value);
    const [ano, setAno] = useState(anos[0]);
    const [mesSelecionado, setMesSelecionado] = useState(mesAutal);
    const [tipoCusto, setTipoCusto] = useState(tiposCusto[1])
    const [tipoDesempenho, setTipoDesempenho] = useState(tiposDesempenho[0])




    const [carregandoNegocio, setcarregandoNegocio] = useState(false);
    const [carregandoRede, setCarregandoRede] = useState(false);
    const [carregandoLoja, setCarregandoLoja] = useState(false);
    const [carregandoComprador, setCarregandoComprador] = useState(false);



    const dispatch = useDispatch();
    const [config, setConfig] = useState({});

    const atualizarDados = async () => {
        await dispatch(requisitarDadosFilial(token));
        await dispatch(requisitarDadosNegocio(token, { u_unidades_id: filialSelecionada }));
        await dispatch(requisitarDadosRede(token, { u_unidades_id: filialSelecionada, u_negocios_id: negocioSelecionado }));
        await dispatch(requisitarDadosLoja(token, { u_unidades_id: filialSelecionada, u_negocios_id: negocioSelecionado, r_redes_id: redeSelecionada }));
        await dispatch(requisitarDadosComprador(token, { u_unidades_id: filialSelecionada }));
        await dispatch(requisitarDadosVendedor(token));
        await dispatch(requisitarDadosGrupoProduto(token));
        await dispatch(requisitarDados(token));
    }

    useEffect(() => {
        setConfig({
            icone: localStorage.getItem("iconeBizAtual"),
            titulo: localStorage.getItem("tituloBizAtual")
        })
        atualizarDados();
    }, [])


    useEffect(() => {
        setcarregandoNegocio(true);
        setCarregandoRede(true);
        setCarregandoLoja(true);
        setCarregandoComprador(true);

        setNegocio("");
        setRede("");
        setLoja("");
        setLoja("");
        setNegocioSelecionado("");
        setRedeSelecionada("");


        dispatch(requisitarDadosNegocio(token, { u_unidades_id: filialSelecionada.trim() }));
        dispatch(requisitarDadosRede(token, { u_unidades_id: filialSelecionada.trim(), u_negocios_id: negocioSelecionado.trim() }));
        dispatch(requisitarDadosLoja(token, { u_unidades_id: filialSelecionada.trim(), u_negocios_id: negocioSelecionado.trim(), r_redes_id: redeSelecionada.trim(), }));
        dispatch(requisitarDadosComprador(token, { u_unidades_id: filialSelecionada.trim() }));

        setTimeout(() => {
            setcarregandoNegocio(false);
            setCarregandoRede(false);
            setCarregandoLoja(false);
            setCarregandoComprador(false);

        }, 1000);
    }, [filial])

    useEffect(() => {
        setNegocio("");
        setRede("");
        setLoja("");
        setNegocioSelecionado("");
        setRedeSelecionada("");

    }, [tipoDesempenho])

    useEffect(() => {
        setCarregandoRede(true);
        setCarregandoLoja(true);

        setRede("");
        setLoja("");
        setRedeSelecionada("");


        dispatch(requisitarDadosRede(token, { u_unidades_id: filialSelecionada.trim(), u_negocios_id: negocioSelecionado.trim() }));
        dispatch(requisitarDadosLoja(token, { u_unidades_id: filialSelecionada.trim(), u_negocios_id: negocioSelecionado.trim(), r_redes_id: redeSelecionada.trim(), }));
        setTimeout(() => {
            setCarregandoRede(false);
            setCarregandoLoja(false);
        }, 1000);
    }, [negocio])

    useEffect(() => {
        setCarregandoLoja(true);
        setLoja("");


        dispatch(requisitarDadosLoja(token, { u_unidades_id: filialSelecionada.trim(), u_negocios_id: negocioSelecionado.trim(), r_redes_id: redeSelecionada.trim(), }));
        setTimeout(() => {
            setCarregandoLoja(false);
        }, 1000);
    }, [rede])

    const filtrar = () => {
        navigate(`/${pagina}`, {
            state: {
                filtros: {
                    filial: filialSelecionada?.value || filialSelecionada,
                    negocio: negocioSelecionado?.value || negocioSelecionado,
                    rede: redeSelecionada?.value || redeSelecionada,
                    loja: loja?.value || loja,
                    tipoPeriodo: tipoPeriodo?.value || tipoPeriodo,
                    tipoDesempenho: tipoDesempenho?.value || tipoDesempenho,
                    ano: ano?.value || ano,
                    mes: mesSelecionado?.value ? ArrayToMultiSelect([mesSelecionado?.value]) : ArrayToMultiSelect([mesSelecionado]),
                    tipoCusto: tipoCusto?.value || tipoCusto
                }
            }
        })
    }
    //======controle dos filtros==========

    const trocarFilial = (valor) => {
        if (valor) {
            setFilial(valor || " ");
            setFilialSelecionada(valor?.value ? valor?.value.replace("--,", "").replace("-", "|") : " ");
        } else {
            setFilial("");
            setFilialSelecionada("");
        }


    }
    const trocarNegocios = (valor) => {
        if (valor) {
            setNegocio(valor || "");
            setNegocioSelecionado(valor?.value ? ArrayToMultiSelect([valor?.value]) : "");
        } else {
            setNegocio("");
        }
    }
    const trocarRedes = (valor) => {
        if (valor) {
            setRedeSelecionada(valor?.value ? ArrayToMultiSelect([valor?.value]) : "");
            setRede(valor || "");
        } else {
            setRede("");
        }
    }
    const trocarLojas = (valor) => {
        if (valor) {
            setLoja(valor || null);
        } else {
            setLoja("");
        }
    }

    const trocarAno = (valor) => {
        if (valor) {
            setAno(valor)
        } else {
            setAno("")
        }
    }
    const trocarMes = (valor) => {
        if (valor) {
            setMesSelecionado(valor)
        } else {
            setMesSelecionado("")
        }
    }
    const trocarTipoCustos = (valor) => {
        if (valor) {
            setTipoCusto(valor)
        } else {
            setTipoCusto("")
        }
    }
    const trocarTipoDesempenho = (valor) => {
        if (valor) {
            setTipoDesempenho(valor)
        } else {
            setTipoDesempenho("")
        }
    }
    const mostrarLoader = carregando && (atalhos.length >= 0) && (menuPrincipal.length <= 0);
    return (
        <React.Fragment>
            <PaginaLoader carregando={mostrarLoader} />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={config.titulo} links={[
                        { nome: "Pagina principal", url: "/", ativa: false },
                        { nome: config.titulo, url: "#", ativa: true },
                    ]} />
                    <Card>
                        <CardBody>
                            <AvForm onSubmit={(event, errors, values) => { filtrar(errors, values) }}>
                                <Row>
                                    <Col sm={3}>
                                        {
                                            filiais &&
                                            <FormGroup>
                                                <Label>Filial</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isClearable={true}
                                                        components={animatedComponents}
                                                        options={filiais}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        value={filial || null}
                                                        onChange={Object => {
                                                            trocarFilial(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        }

                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup inline>
                                            <Label>Ano</Label>
                                            <div className="unidadesControler anoControler">
                                                <Select
                                                    placeholder="Todas as unidades"
                                                    className="basic-single"
                                                    options={anos}
                                                    isSearchable={false}
                                                    classNamePrefix="select2-selection"
                                                    defaultValue={anos[0] || []}
                                                    value={ano || null}
                                                    onChange={Object => {
                                                        trocarAno(Object || []);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup className="select2-container" inline >
                                            <Label>Mês</Label>
                                            <div className="unidadesControler">
                                                <Select
                                                    placeholder="Todas as unidades"
                                                    className="basic-single"
                                                    options={meses}
                                                    isSearchable={false}
                                                    classNamePrefix="select2-selection"
                                                    defaultValue={mesAutal}
                                                    value={mesSelecionado || null}
                                                    onChange={Object => {
                                                        trocarMes(Object || []);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup className="select2-container" inline >
                                            <Label>Tipo de desempenho</Label>
                                            <div className="unidadesControler">
                                                <Select
                                                    placeholder="Todas as unidades"
                                                    className="basic-single"
                                                    options={tiposDesempenho}
                                                    isSearchable={false}
                                                    classNamePrefix="select2-selection"
                                                    defaultValue={tiposDesempenho[0] || []}
                                                    value={tipoDesempenho || null}
                                                    onChange={Object => {
                                                        trocarTipoDesempenho(Object || []);
                                                    }}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    {
                                        negocios &&
                                        (tipoDesempenho.value == "negocio" || tipoDesempenho.value == "rede" || tipoDesempenho.value == "cliente") &&
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Negócios</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isLoading={carregandoNegocio}
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={negocios}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        value={negocio || null}
                                                        onChange={Object => {
                                                            trocarNegocios(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    }
                                    {
                                        redes &&
                                        (tipoDesempenho.value == "cliente" || tipoDesempenho.value == "rede") &&
                                        <Col sm={3}>
                                            <FormGroup>
                                                <Label>Redes</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isLoading={carregandoRede}
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={redes}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        value={rede || null}
                                                        onChange={Object => {
                                                            trocarRedes(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>

                                        </Col>
                                    }
                                    {
                                        lojas &&
                                        (tipoDesempenho.value == "cliente") &&
                                        <Col sm={3}>

                                            <FormGroup>
                                                <Label>Lojas</Label>
                                                <div className="unidadesControler">
                                                    <Select
                                                        placeholder="Todos"
                                                        isLoading={carregandoLoja}
                                                        isClearable={true}
                                                        className="basic-single"
                                                        options={lojas}
                                                        classNamePrefix="select2-selection"
                                                        isSearchable={true}
                                                        value={loja || null}
                                                        onChange={Object => {
                                                            trocarLojas(Object || []);
                                                        }}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col sm={3}>
                                        <div className="unidadesControler">
                                            <Label>Tipo de custo</Label>
                                            <Select
                                                placeholder=""
                                                className="basic-single"
                                                options={tiposCusto}
                                                isSearchable={false}
                                                classNamePrefix="select2-selection"
                                                defaultValue={tiposCusto[1] || []}
                                                value={tipoCusto || null}
                                                onChange={Object => {
                                                    trocarTipoCustos(Object || []);
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <div className={"d-grid gap-2 d-md-flex justify-content-md-end"}>
                                    <Button color="light" type="submit" >
                                        <i className="fal fa-hashtag"></i>
                                        boralá!
                                    </Button>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default PreFiltroUnidades;