import React, { useEffect, useCallback, useState, useRef } from 'react';
import PaginaLoader from '../../../components/loader/pagina';
import gerarToken from '../../../helpers/gerarToken'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { requisitarDados } from "../../../store/btflex/listagemPedidosEstagios/actions"
import { gridConfig } from './AppConfig';
import '@bryntum/grid/grid.stockholm.css';
import '../../../App.scss';
import Header from "../../../components/HorizontalLayout/HeaderApp"
import { BryntumGrid } from '@bryntum/grid-react';
const ListagemPedidosEstagios = () => {
    const { mes, ano, status, estagio, vendedor } = useParams();
    const { dados, carregando } = useSelector(state => state.ListagemPedidosEstagios);
    const grid = useRef(null);
    const dispatch = useDispatch();
    const vendedorFinal = vendedor === 'todos' ? '' : vendedor;

    const atualizarDados = async () => {
        const token = await gerarToken();
        await dispatch(requisitarDados({ token, ano, mes, status, estagio, vendedor: vendedorFinal }));
      };
      
    useEffect(() => {
        atualizarDados();
    }, []);

    if (!dados) return <PaginaLoader carregando={true} />
    const gridInfo = gridConfig(dados, estagio);
    return (
        <React.Fragment>
            <PaginaLoader carregando={carregando} />
            <>
                <Header
                    parametros={{}}
                    tituloPagina={estagio}
                    url="/indicadores-de-vendas/estagios"
                />
                <BryntumGrid
                    ref={grid}
                    {...gridInfo}
                    treeFeature={true}
                    columnReordFeature={false}
                    cellEditFeature={false}
                    summaryFeature={true}
                    sortFeature={false}
                    cellMenuFeature={
                        {
                            disabled: true,
                        }
                    }
                    headerMenuFeature={
                        {
                            items: {
                                hideColumn: false
                            }
                        }
                    }
                />
            </>
        </React.Fragment>
    );
}
export default ListagemPedidosEstagios;